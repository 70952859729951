import React from 'react';
import BookItem from './BookItem';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Books(props) {
  const books = [
    {
    name: "Dune",
    author: "Frank Herbert",
    year: 2024,
    category: "fiction",
    link: "https://www.goodreads.com/book/show/44767458-dune",
  },
  {
    name: "Slow Productivity",
    author: "Cal Newport",
    year: 2024,
    category: "productivity",
    link: "https://calnewport.com/my-new-book-slow-productivity/",
  },
  {
    name: "Gone for Good",
    author: "Harlan Coben",
    year: 2024,
    category: "fiction",
    link: "https://www.goodreads.com/book/show/43930.Gone_for_Good?from_search=true&from_srp=true&qid=3jbW3c6Z6z&rank=1",
  },
  {
    name: "Do Hard Things",
    author: "Steve Magness",
    year: 2024,
    category: "fitness",
    link: "https://www.goodreads.com/book/show/59088361-do-hard-things?from_search=true&from_srp=true&qid=0yFLFDkfXJ&rank=3",
  },
  {
    name: "When Breath Becomes Air",
    author: "Paul Kalanithi",
    year: 2023,
    category: "memoir",
    link: "https://www.goodreads.com/book/show/25899336-when-breath-becomes-air?from_search=true&from_srp=true&qid=zn7FJ13o2p&rank=1",
  },
  {
    name: "Carrie Soto Is Back",
    author: "Taylor Jenkins Reid",
    year: 2023,
    category: "fiction",
    link: "https://www.goodreads.com/book/show/60435878-carrie-soto-is-back?ref=nav_sb_ss_1_11",
  },
  {
    name: "American Dirt",
    author: "Jeanine Cummins",
    year: 2023,
    category: "fiction",
    link: "https://www.goodreads.com/book/show/45046527-american-dirt?ref=nav_sb_ss_1_12",
  },
  {
    name: "In The Spell of The Barkley",
    author: "Michael Panhuysen",
    year: 2023,
    category: "ultra-running",
    link: "https://www.goodreads.com/book/show/78819448-in-the-spell-of-the-barkley?ref=nav_sb_ss_1_27",
  },
  {
    name: "Gone Girl",
    author: "Gillian Flynn",
    year: 2023,
    category: "fiction",
    link: "https://www.goodreads.com/book/show/19288043-gone-girl?ref=nav_sb_ss_1_9",
  },
  {
    name: "The Hounds of the Baskervilles",
    author: "Arthur Conan Doyle",
    year: 2023,
    category: "fiction",
    link: "https://www.goodreads.com/book/show/8921.The_Hound_of_the_Baskervilles?ref=nav_sb_ss_1_10",
  },
  {
    name: "The Deep Deep Snow",
    author: "Brian Freeman",
    year: 2023,
    category: "fiction",
    link: "https://www.goodreads.com/book/show/52282213-the-deep-deep-snow?ref=nav_sb_ss_1_18",
  },
  {
    name: "The Maid",
    author: "Nita Prose",
    year: 2023,
    category: "fiction",
    link: "https://www.goodreads.com/book/show/55196813-the-maid?ref=nav_sb_ss_1_8",
  },
  {
    name: "Solo",
    author: "Jenny Tough",
    year: 2023,
    category: "ultra-running",
    link: "https://www.goodreads.com/book/show/60099281-solo?from_search=true&from_srp=true&qid=uCPPW1XVf6&rank=1",
  },
  {
    name: "Coasting",
    author: "Elise Downing",
    year: 2023,
    category: "ultra-running",
    link: "https://www.goodreads.com/book/show/57732344-coasting?from_search=true&from_srp=true&qid=z4gPJAdFn5&rank=4",
  },
  {
    name: "Cilkas Journey",
    author: "Heather Morris",
    year: 2022,
    category: "historical",
    link: "https://www.goodreads.com/book/show/45033931-cilka-s-journey?ac=1&from_search=true&qid=PYxAp7kyId&rank=1",
  },
  {
    name: "The Tattooist of Auschwitz",
    author: "Heather Morris",
    year: 2022,
    category: "historical",
    link: "https://www.goodreads.com/book/show/38359036-the-tattooist-of-auschwitz?ref=nav_sb_ss_2_5",
  },
  {
    name: "In it for the Long Run",
    author: "Damien Hall",
    year: 2022,
    category: "ultra-running",
    link: "https://www.goodreads.com/book/show/57670263-in-it-for-the-long-run?ref=nav_sb_ss_1_18",
  },
  {
    name: "How to Build a Car",
    author: "Adrian Newey",
    year: 2022,
    category: "f1",
    link: "https://www.goodreads.com/book/show/35657708-how-to-build-a-car?ref=nav_sb_ss_4_12",
  },
  {
    name: "Greenlights",
    author: "Matthew McConaughey",
    year: 2022,
    category: "memoir",
    link: "https://www.goodreads.com/book/show/52838315-greenlights?ref=nav_sb_ss_1_11",
  },
  {
    name: "Failure Is an Option: On the Trail of the World’s Toughest Mountain Race",
    author: "Matt Whyman",
    year: 2022,
    category: "ultra-running",
    link: "https://www.audible.co.uk/pd/Failure-Is-an-Option-Audiobook/B09X27RV8Y",
  },
  {
    name: "Above the Clouds: How I Carved My Own Path to the Top of the World",
    author: "Killian Jornet",
    year: 2021,
    category: "ultra-running",
    link: "https://www.audible.co.uk/author/B006IW88NK",
  },
  {
    name: "What I Talk About When I Talk About Running",
    author: "Haruki Murakami",
    year: 2021,
    category: "ultra-running",
    link: "https://www.audible.co.uk/pd/What-I-Talk-About-When-I-Talk-About-Running-Audiobook/B01GSDR03C",
  },
  {
    name: "The Rise of the Ultra Runners: A Journey to the Edge of Human Endurance",
    author: "Adharanand Finn",
    year: 2021,
    category: "ultra-running",
    link: "https://www.audible.co.uk/pd/The-Rise-of-the-Ultra-Runners-Audiobook/0571354289",
  },
  {
    name: "Wild",
    author: "Cheryl Strayed",
    year: 2021,
    category: "memoir",
    link: "https://www.audible.co.uk/pd/Wild-Audiobook/B00AQMGNZS",
  },
  {
    name: "Touching the Void",
    author: "Joe Simpson",
    year: 2021,
    category: "memoir",
    link: "https://www.audible.co.uk/pd/Touching-the-Void-Audiobook/B007D1DLUA",
  },
  {
    name: "Moneyball: The Art of Winning an Unfair Game",
    author: "Michael Lewis",
    year: 2021,
    category: "",
    link: "https://www.audible.co.uk/author/B000APZ33E",
  },
  {
    name: "Shoe Dog",
    author: "Phil Knight",
    year: 2021,
    category: "memoir",
    link: "https://www.audible.co.uk/pd/Shoe-Dog-Audiobook/B06XSS47BK",
  },
  {
    name: "Atomic Habits: An Easy and Proven Way to Build Good Habits and Break Bad Ones",
    author: "James Clear",
    year: 2020,
    category: "self-help",
    link: "https://www.audible.co.uk/pd/Atomic-Habits-Audiobook/1473565421",
  },
  {
    name: "Deep Work",
    author: "Cal Newport",
    year: 2020,
    category: "productivity",
    link: "https://www.goodreads.com/book/show/25744928-deep-work?from_search=true&from_srp=true&qid=LgAmQytET4&rank=1",
  },
  {
    name: "How to Own the World",
    author: "Andrew Craig",
    year: 2019,
    category: "investment",
    link: "https://www.audible.co.uk/pd/How-to-Own-the-World-Audiobook/1473695333",
  },
  {
    name: "Born to Run",
    author: "Christopher McDougall",
    year: 2019,
    category: "ultra-running",
    link: "https://www.audible.co.uk/pd/Born-to-Run-Audiobook/B007RFPNE4",
  },
  {
    name: "Into Thin Air",
    author: "Jon Krakauer",
    year: 2019,
    category: "exploration",
    link: "https://www.audible.co.uk/pd/Into-Thin-Air-Audiobook/B01C99MHM8",
  },
  
  
]

  return <Container>
    <Row>
      <h3 className="dark-color">Selection from the library</h3>
      
    </Row>
    <p>
        title | authour | year read | category
      </p>
    <Row>
      <Col lg={6} className="col-6">
        <ul style={{listStyle: 'none'}}>
          {
            books.slice(0, 20).map((book) => <BookItem book={book} />)
          }
        </ul>
      </Col>
      <Col lg={6} className="col-6">
        <ul style={{listStyle: 'none'}}>
          {
            books.slice(20, books.length-1).map((book) => <BookItem book={book} />)
          }
        </ul>
      </Col>
    </Row>
  </Container>
  ;
}
